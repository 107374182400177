import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultContractType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_contract_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListContractType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_contract_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getContractType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_contract_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addContractType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_contract_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editContractType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/edit_contract_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteContractType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_contract_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getContractTypeOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/contract_type_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
